<template>
  <div class="page-miniform">
    <sliders
      ref="sliders"
      :disable-button="true"
      :style-classes="['subpage_sliders__style-2']"
      :slider-style="1"
      :go-to-url="goToUrl"
      :business-loan="businessLoan"
      :vauraus-loan="vaurausLoan"
    >
      <div class="columns is-multiline">
        <div class="column is-6 no-padding-bottom">
          <form-input
            key="form.etunimi"
            v-model="form.etunimi"
            v-validate="'required|min:2|max:40'"
            :error="errors.first('etunimi')"
            name="etunimi"
            label="Etunimi"
            placeholder="Etunimi"
          />
        </div>
        <div class="column is-6 no-padding-bottom">
          <form-input
            key="form.sukunimi"
            v-model="form.sukunimi"
            v-validate="'required|min:2|max:40'"
            :error="errors.first('sukunimi')"
            name="sukunimi"
            label="Sukunimi"
            placeholder="Sukunimi"
          />
        </div>
        <div class="column is-6 no-padding-bottom">
          <form-input
            key="form.email"
            v-model="form.email"
            v-validate="'required|email'"
            :error="errors.first('email')"
            name="email"
            type="email"
            label="Sähköpostiosoite"
            placeholder="Sähköpostiosoite"
            tooltip="Alustava päätös ilmoitetaan sähköpostitse. Lainatarjous toimitetaan usein sähköisesti."
          />
        </div>
        <div class="column is-6 no-padding-bottom">
          <form-input
            key="form.puhelin"
            v-model="form.puhelin"
            v-validate="'required|phone_stripped'"
            :error="errors.first('puhelin')"
            name="puhelin"
            type="tel"
            label="Puhelinnumero"
            placeholder="esim. 040 123 4567"
            tooltip="Ilmoita puhelinnumerosi."
          />
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-right">
          <a class="button is-rounded is-primary" @click="goTo">Kilpailuta lainat</a>
        </div>
      </div>
    </sliders>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input' ),
    sliders: () => import( '~/components/subpage/sliders' )
  },
  props: {
    goToUrl: {
      type: String,
      default: '/'
    },
    businessLoan: {
      type: Boolean,
      default: false
    },
    vaurausLoan: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        etunimi: '',
        sukunimi: '',
        email: '',
        puhelin: ''
      }
    }
  },
  methods: {
    async goTo () {
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        try {
          this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` ).scrollIntoView()
        }
        catch ( error ) {
          this.$logger.error( error, {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          } )
        }
        return
      }
      this.$refs.sliders.goToApplication( this.form )
    }
  }
}
</script>

<style lang="scss">
.page-miniform {
  .loan-slider .noUi-target {
    background-color: #fff;
  }
}
</style>
