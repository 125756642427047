<template>
  <div class="columns is-mobile is-centered has-background-light">
    <div class="column">
      <searchApplication />
      <savedApplications />
    </div>
    <div class="column is-7">
      <nuxt-child />
      <div v-if="this.$route.name === 'cc-new'" class="content">
        <h1>Hakemus on nyt täytetty.</h1>
        <p>Valitse seuraavaksi uusi kuluttaja- tai yrityshakemus.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  layout: 'cc',
  components: {
    searchApplication: () => import( '~/components/cc/searchApplication' ),
    savedApplications: () => import( '~/components/cc/savedApplications' )
  },
  head () {
    return {
      title: 'Rahalaitos | Call Center'
    }
  },
  data () {
    return {
    }
  },
  async asyncData ( { app, store, redirect } ) {
    // Validate if user is sign in
    const result = await app.$axios.$get( '/v1/cc', {
      validateStatus: function ( status ) {
        return status === 200 || status === 401
      }
    } )
    if ( result.status === 401 ) {
      redirect( '/cc-new/login' )
    }
  },
  beforeCreate () {
    if ( this.$route.name === 'cc-new' ) {
      this.$router.replace( '/cc-new/kuluttaja' )
    }
  }
}
</script>
