<template>
  <business-application />
</template>

<script>
export default {
  components: {
    businessApplication: () => import( '~/components/businessApplication' )
  }
}
</script>
