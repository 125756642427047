<template>
  <loan-application />
</template>

<script>
export default {
  components: {
    loanApplication: () => import( '~/components/loanApplication' )
  }
}
</script>
